<template>
  <validation-observer ref="simpleRules">
    <b-card
      class="pt-1 pb-1"
      style="max-width: 1200px;"
    >
      <b-form @submit.prevent>
        <b-card-text>
          <h4>Basic Info</h4>
        </b-card-text>
        <b-row class="mt-2 mb-2 align-items-center  pl-lg-5 pr-lg-5 pl-md-2 pr-md-2">
          <b-col
            md="6"
          >
            <b-form-group
              label="Username"
              label-for="mc-username"
              label-cols-md="4"
              class="mr-lg-3"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  id="mc-username"
                  v-model="form.username"
                  placeholder="Username"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nickname"
              label-for="mc-nickname"
              label-cols-md="4"
              class="ml-lg-3"
            >
              <b-form-input
                id="mc-nickname"
                v-model="form.nickName"
                placeholder="Nickname"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="รหัสผ่าน"
              label-for="mc-nickname"
              label-cols-md="4"
              class="mr-lg-3"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click.prevent="modalPassword = true"
              >
                เเก้ไขรหัสผ่าน
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2 mt-md-4 mb-2">
          <b-col
            cols="12"
            md="6"
          >
            <b-card-text>
              <h4>Credit</h4>
            </b-card-text>
            <div
              class="pl-lg-5 pl-md-2"
            >
              <b-form-group
                label="Credit"
                label-for="credit"
                label-cols-md="4"
                class="mr-lg-3"
              >
                <b-form-input
                  id="credit"
                  v-model="form.balance"
                  disabled
                />
              </b-form-group>
            </div>
            <!--            <div-->
            <!--              class="pl-lg-5 pl-md-2"-->
            <!--            >-->
            <!--              <b-form-group-->
            <!--                label="Bet"-->
            <!--                label-for="bet"-->
            <!--                label-cols-md="4"-->
            <!--                class="mr-lg-3"-->
            <!--              >-->
            <!--                <b-form-input-->
            <!--                  id="bet"-->
            <!--                  v-model="form.rolling"-->
            <!--                  disabled-->
            <!--                />-->
            <!--              </b-form-group>-->
            <!--            </div>-->
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-card-text class="mr-5 mt-1 mt-md-0">
              <h4>Status</h4>
            </b-card-text>
            <div
              class="pr-lg-5 pr-md-2"
            >
              <b-form-group
                label="Suspend"
                label-for="Suspend"
                label-cols-md="4"
                class="ml-lg-3"
              >
                <b-row class="align-items-center justify-content-start no-gutters">
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-input
                      disabled
                      :value="isEnable === true ? 'No' : 'Yes'"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="8"
                    class="pl-md-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="text-nowrap"
                      @click.prevent="submitStatus"
                    >
                      Edit Status
                    </b-button>
                  </b-col>
                </b-row>

              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <div class="text-center text-sm-left">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click.prevent="onSubmitForm"
          >
            Submit
          </b-button>
        </div>
      </b-form>
    </b-card>

    <b-modal
      v-model="modalPassword"
      cancel-variant="secondary"
      ok-only
      ok-title="Submit"
      centered
      title="เปลี่ยนรหัสผ่าน"
      button-size="sm"
      @ok="submitPassword"
    >
      <b-form @submit.prevent>
        <b-form-group
          label="Password"
          label-for="mc-password"
        >
          <validation-provider
            #default="{ errors }"
            name="Password"
            vid="Password"
            rules="required|min:8|password"
          >
            <b-form-input
              id="mc-password"
              v-model="passwordForm.password"
              autofocus
              placeholder="Password"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Confirm Password"
          label-for="mc-confirm-password"
        >
          <validation-provider
            #default="{ errors }"
            name="Confirm Password"
            rules="required|confirmed:Password"
          >
            <b-form-input
              id="mc-confirm-password"
              v-model="passwordForm.passwordConfirm"
              :state="errors.length > 0 ? false:null"
              placeholder="Confirm Password"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import customerService from '@/service/customerService'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        username: '',
        nickName: '',
      },
      isEnable: false,

      modalPassword: false,
      passwordForm: {
        password: '',
        passwordConfirm: '',
      },
    }
  },
  async mounted() {
    const username = this.$router.currentRoute.query.id
    if (username !== null && username !== undefined) {
      const res = await customerService.getAuthen(`find?username=${username}`)
      if (res.status === 200) {
        this.form = res.data
        this.isEnable = res.data.isEnable
      }
    }
  },
  methods: {
    onSubmitForm() {
      this.$refs.simpleRules.validate()
        .then(async success => {
          if (success) {
            this.$swal({
              dark: true,
              title: 'Are you sure?',
              text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
              .then(async result => {
                if (result.value) {
                  const formSender = { ...this.form }
                  const res = await customerService.putAuthen(`?username=${this.form.username}`, formSender)
                  if (res.status === 200) {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'success',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: res.data,
                      },
                    })
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Error',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                        text: res.data,
                      },
                    })
                  }
                }
              })
          }
        })
    },
    submitStatus() {
      this.$refs.simpleRules.validate()
        .then(async success => {
          if (success) {
            this.$swal({
              dark: true,
              title: 'Lock / Unlock Account',
              text: 'คุณเเน่ใจว่าต้องการดำเนินการใช่หรือไม่ ?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
              .then(async result => {
                if (result.value) {
                  const res = await customerService.putAuthen(`toggle-status?username=${this.form.username}`)
                  if (res.status === 200) {
                    this.isEnable = res.data
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'success',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'ดำเนินการสำเร็จ',
                      },
                    })
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Error',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                        text: res.data,
                      },
                    })
                  }
                }
              })
          }
        })
    },
    submitPassword(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate()
        .then(async success => {
          if (success) {
            this.$swal({
              dark: true,
              title: 'Are you sure?',
              text: 'คุณเเน่ใจว่าต้องการเปลี่ยนรหัสผ่านใช่หรือไม่',
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
              .then(async result => {
                if (result.value) {
                  const formSender = { ...this.passwordForm }
                  delete formSender.passwordConfirm

                  const res = await customerService.putAuthen(`password?username=${this.form.username}`, formSender)
                  if (res.status === 200) {
                    this.modalPassword = false
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'success',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: res.data,
                      },
                    })
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Error',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                        text: res.data,
                      },
                    })
                  }
                }
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

</style>
