import request from '@/service/request'

export default {
  ...request,
  base_url: 'https://api-huaydragon.com/customer/',
  // base_url : "http://localhost:5002/customer/",
  getURL() {
    return this.base_url
  },
}
